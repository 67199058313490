<!-- //Home Page -->
<!-- <app-loader [ShowLoader]="globalService.showLoader"></app-loader>
 -->

<!-- Home list -->
<div class="home-page row" *ngIf="resturantLocationStatus == 'home'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 mb-4 col-xs-12" *ngFor="let obj of resturantResponseArrayModel.ResturantLists| slice:0:4;">
        <div class="wrapper-list-thumb">
            <div class="room-img-wrapper ">
                <a [routerLink]="['/restaurants',obj._id,false]">
                    <img [src]="obj.ResturantImages[0]" *ngIf="obj.ResturantImages && obj.ResturantImages[0];else  otherImage" />
                    <ng-template #otherImage>
                        <img src="assets/images/No_Image_Available.jpg" />
                    </ng-template>
                </a>
            </div>
            <div class="list-thumb-content">
                <p class="type-info  secondary-text" *ngIf="obj.ResturantType && obj.ResturantType.ResturantTypeLangs && obj.ResturantType.ResturantTypeLangs.length;else otherResturant">
                    {{ obj.ResturantType.ResturantTypeLangs[0].Value }}</p>
                <ng-template #otherResturant>
                    <p class="type-info  secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info primary-text" *ngIf="obj.ResturantNameLangs && obj.ResturantNameLangs.length;else otherResturantName">
                    {{ obj.ResturantNameLangs[0].Value }}
                </p>
                <ng-template #otherResturantName>
                    <p class="title-info primary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<!-- //Home list -->

<!-- Home Page Menu Page -->
<div class="home-page row" *ngIf="resturantLocationStatus == 'homePageMenu'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 mb-4 col-xs-12" *ngFor="let obj of resturantResponseArrayModel.ResturantLists">
        <div class="wrapper-list-thumb position-relative wrapper-list-thumb-manage">
            <div class="delete-image"> <i class="material-icons" (click)="deactivateFromHome(obj._id)" [appAccessCheck]="'homepage-menu'" [givenButton]="'delete'">delete</i></div>
            <div class="room-img-wrapper">
                <img [src]="obj.ResturantImages[0]" *ngIf="obj.ResturantImages && obj.ResturantImages[0];else  otherImage" />
                <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                </ng-template>
            </div>
            <div class="list-thumb-content">
                <p class="type-info  secondary-text" *ngIf="obj.ResturantType && obj.ResturantType.ResturantTypeLangs && obj.ResturantType.ResturantTypeLangs.length;else otherResturant">
                    {{ obj.ResturantType.ResturantTypeLangs[0].Value }}</p>
                <ng-template #otherResturant>
                    <p class="type-info  secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info primary-text" *ngIf="obj.ResturantNameLangs && obj.ResturantNameLangs.length;else otherResturantName">
                    {{ obj.ResturantNameLangs[0].Value }}
                </p>
                <ng-template #otherResturantName>
                    <p class="title-info primary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>



<!---Home Front View-->
<div class="room-front-view row" *ngIf="resturantLocationStatus == 'resturantFrontView'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 mb-4 col-xs-12" *ngFor="let obj of resturantResponseArrayModel.ResturantLists">
        <div class="wrapper-list-thumb">
            <div class="room-img-wrapper">
                <a [routerLink]="['/restaurants',obj._id,false]">
                    <img [src]="obj.ResturantImages[0]" *ngIf="obj.ResturantImages && obj.ResturantImages[0];else  otherImage" />
                    <ng-template #otherImage>
                        <img src="assets/images/No_Image_Available.jpg" />
                    </ng-template>
                </a>
            </div>
            <div class="list-thumb-content">
                <a [routerLink]="['/restaurants',obj._id,false]">
                    <p class="type-info  secondary-text" *ngIf="!globalService.validateIsEmpty(obj.ResturantType?.ResturantTypeLangs);else otherResturant">
                        {{ obj.ResturantType.ResturantTypeLangs[0].Value }}</p>
                    <ng-template #otherResturant>
                        <p class="type-info  secondary-text">{{ 'NotAvailable'|translate }}</p>
                    </ng-template>
                    <p class="title-info primary-text" *ngIf="obj.ResturantNameLangs && obj.ResturantNameLangs.length;else otherResturantName">
                        {{ obj.ResturantNameLangs[0].Value }}
                    </p>
                    <ng-template #otherResturantName>
                        <p class="title-info primary-text">{{ 'NotAvailable'|translate }}</p>
                    </ng-template>

                    <p class="description-heading-list">
                        <span class="secondary-text ckeditor-front" *ngIf="obj.ResturantDescriptionLangs && obj.ResturantDescriptionLangs.length;else otherResturantDescription" [innerHTML]="obj.ResturantDescriptionLangs[0].Value| slice:0:60">
            </span>
                    </p>



                    <!-- <div class="secondary-text" *ngIf="obj.ResturantDescriptionLangs && obj.ResturantDescriptionLangs.length && obj.ResturantDescriptionLangs[0].Value.length > 60">
                        ---
                    </div> -->
                    <ng-template #otherResturantDescription>
                        <div class="secondary-text">{{ 'NotAvailable'|translate }}</div>
                    </ng-template>

                </a>
            </div>
        </div>
    </div>

    <div class="pagination" *ngIf="resturantResponseArrayModel.ResturantLists">
        <span *ngIf="resturantResponseArrayModel.ResturantLists.length">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
      </mat-paginator>
    </span>
    </div>

</div>
<!---//Home Front View-->

<!---Restaurant Manage Page-->
<div class="room-manage-page row" *ngIf="resturantLocationStatus == 'resturantManagePage'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 mb-4 col-xs-12" *ngFor="let obj of resturantResponseArrayModel.ResturantLists">
        <div class="wrapper-list-thumb position-relative">
            <div class="room-img-wrapper">
                <img [src]="obj.ResturantImages[0]" *ngIf="obj.ResturantImages && obj.ResturantImages[0];else  otherImage" />
                <ng-template #otherImage>
                    <img src="assets/images/No_Image_Available.jpg" />
                </ng-template>
            </div>
            <span class="restock-warning nunito position-absolute" *ngIf="thresholdCheck(obj._id)" >{{'SoonOutofStock'|translate}}</span>
            <div class="list-thumb-content">
                <p class="type-info  secondary-text" *ngIf="obj.ResturantType && obj.ResturantType.ResturantTypeLangs && obj.ResturantType.ResturantTypeLangs.length;else otherResturant">
                    {{ obj.ResturantType.ResturantTypeLangs[0].Value }}</p>
                <ng-template #otherResturant>
                    <p class="type-info  secondary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="title-info primary-text" *ngIf="obj.ResturantNameLangs && obj.ResturantNameLangs.length;else otherResturantName">
                    {{ obj.ResturantNameLangs[0].Value }}
                </p>
                <ng-template #otherResturantName>
                    <p class="title-info primary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
            </div>
            <div class="button-section">
                <button class="btn btn-primary" type="button" (click)="detailResturantView(obj._id)" [appAccessCheck]="'restaurants'" [givenButton]="'view'"><i class="material-icons">remove_red_eye</i>{{'ViewDetail'|translate}}</button>
                <button class="btn btn-secondary" type="button" (click)="editResturantView(obj._id)" [appAccessCheck]="'restaurants'" [givenButton]="'edit'"><i class="material-icons">create</i>{{'Edit'|translate}}</button>
            </div>
        </div>
    </div>

    <div class="pagination" *ngIf="resturantResponseArrayModel.ResturantLists">
        <span *ngIf="resturantResponseArrayModel.ResturantLists.length">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
      </mat-paginator>
    </span>
    </div>
</div>
<!---//Restaurant Manage Page-->