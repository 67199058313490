<app-loader [ShowLoader]="globalService.showLoader"></app-loader>

<!-- top heading and icon -->
<div class="create-pass">
    <div class="icon-wrap"><img src="assets/images/lock-icon.png" /> </div>
    <h1>{{'Welcome'|translate}}!</h1>
    <p>{{ 'Youraccounthasbeencreatedpleasemanageyourpassword'|translate }}</p>
</div>
<!-- // top heading and icon -->
<!-- form wrapper -->
<div class="form-wrapper-create-pass">
    <form class="login-form" [formGroup]="form">
        <div class="inr-form-field-wrap">
            <div class="form-wrapper manage-branding-form-wrapper">
                <mat-form-field class="col-lg-12">
                    <mat-label>{{'Password'|translate}}</mat-label>
                    <input matInput type="password" formControlName="password" placeholder="Password">
                    <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>

                    <small *ngIf="form.controls['password'].hasError('minlength') && form.controls['password'].touched" class="text-danger">{{ 'Validation.PasswordMinlength'|translate }}</small>

                    <small *ngIf="form.controls['password'].hasError('pattern') && form.controls['password'].touched" class="text-danger">{{ 'Validation.PasswordValidate'|translate }}</small>
                    <small *ngIf="form.controls['password'].touched && form.get('password').value != this.form.get('confirmPassword').value" class="text-danger">{{ 'Passwordmismatch'|translate }}</small>

                </mat-form-field>
                <mat-form-field class="col-lg-12">
                    <mat-label>{{'ConfirmPassword'|translate}}</mat-label>
                    <input matInput type="password" formControlName="confirmPassword" placeholder="Confirm Password">
                    <small *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                    <small *ngIf="form.controls['confirmPassword'].hasError('passwordMatch') && form.controls['confirmPassword'].touched " class="text-danger">{{ 'Passwordmismatch'|translate }}</small>
                </mat-form-field>
            </div>
        </div>
        <button class="btn btn-primary ml-5" (click)="save(form.value)" [disabled]="!form.valid || this.form.get('password').value != this.form.get('confirmPassword').value">
      <i class="material-icons">check</i>
      {{'SaveandContinue'|translate }}
    </button>
    </form>
</div>
<!-- //form wrapper -->