import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { MasterService } from "../../services/master.service";
import { SharedService } from "src/app/shared.service";
import { GlobalService } from "../../services/global.service";
import { takeUntil } from "rxjs/operators";
import {
  ServiceResponseArrayModel,
  ServiceResponseModel
} from "../../models/common-request-response";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { JwtService } from "../../services/jwt.service";

@Component({
  selector: "app-hotel-services",
  templateUrl: "./hotel-services.component.html",
  styleUrls: ["./hotel-services.component.scss"]
})
export class HotelServicesComponent implements OnInit {
  headerLangHotelChangeEvent$;

  public serviceResponseArrayModel: ServiceResponseArrayModel = new ServiceResponseArrayModel();
  public ServiceResponseModel: ServiceResponseModel = new ServiceResponseModel();
  @Input() serviceLocationStatus: string;
  @Output() detailServiceViewEmit = new EventEmitter();
  @Output() editServiceViewEmit = new EventEmitter();
  @Output() checkServiceCount = new EventEmitter();

  innerBannerShow: boolean = false;
  thresholdServiceId;
  viewAllShow: boolean = true;
  headdingTitle = this.translate.instant("HotelRestuarants");
  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  //Loader
  title = "materialApp";
  color = "primary";
  mode = "indeterminate";
  showLoader: boolean = true;
  public searchControl: UntypedFormControl;
  public servicesList;
  public frontView = "view";
  public isLoggedIn: boolean;
  userRoleRoute: string = '';
  constructor(
    private router: Router,
    private masterService: MasterService,
    private sharedService: SharedService,
    public globalService: GlobalService,
    public translate: TranslateService,
    private jwtService: JwtService
  ) {
    this.userRoleRoute = `/portal/${this.jwtService.getUserRole()}/service-admin-list`;
    this.isLoggedIn = this.globalService.hasCheckInAuthToken();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  ngOnInit() {
    // API Requests For Different Locations
       if (
      this.serviceLocationStatus == "home" ||
      this.serviceLocationStatus == "homePageMenu"
    ) {
      this.globalService.showLoader = false;
      this.showServiceLists({
        Data: "home",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    if (this.serviceLocationStatus == "serviceFrontView"||"serviceAdminView") {
      this.searchControl = new UntypedFormControl();

      this.searchControl.valueChanges
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(result => {
          this.showServiceLists({
            Data: "search",
            Search: result,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            isLoggedIn:this.isLoggedIn
          });
        });

      this.showServiceLists({
        Data: this.serviceLocationStatus == "serviceAdminView" ?this.serviceLocationStatus:"front",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        isLoggedIn:this.isLoggedIn
      });
    }

    if (this.serviceLocationStatus == "serviceManagePage") {
      this.showServiceLists({
        Data: null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    this.headerLangHotelChangeEvent$ = this.sharedService.headerLangChangeEvent.subscribe(
      (data: string) => {
        // API Requests For Different Locations
        if (this.serviceLocationStatus == "home") {
          this.showServiceLists({
            Data: "home",
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
          });
        }

        if (this.serviceLocationStatus == "serviceFrontView"||'serviceAdminView') {          
          this.showServiceLists({
            Data: this.serviceLocationStatus == "serviceAdminView" ?this.serviceLocationStatus:"front",
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            isLoggedIn:this.isLoggedIn
          });
        }

        if (this.serviceLocationStatus == "serviceManagePage") {

          this.showServiceLists({
            Data: null,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
          });
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.headerLangHotelChangeEvent$) {
      this.headerLangHotelChangeEvent$.unsubscribe();
    }
  }

  showServiceLists(data) {
    data.serviceLocationStatus = this.serviceLocationStatus;    
    this.globalService.showLoader = true;
          if (
      this.serviceLocationStatus == "serviceFrontView" ||
      this.serviceLocationStatus == "serviceAdminView" ||
      this.serviceLocationStatus == "home" ||
      this.serviceLocationStatus == "homePageMenu"
    ) {
      if(this.serviceLocationStatus == "serviceAdminView"){
        this.globalService.showLoader = true;
      }else{
        this.globalService.showLoader = true;
      }
      this.masterService
        .showServiceLists(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              if(this.serviceLocationStatus !== "serviceAdminView"){
              for(let i = 0; i < response.Data.ServiceLists.length ; i++){
                if(response.Data.ServiceLists[i].InternalService){
                  response.Data.ServiceLists.splice(i,1);
                }
              }
            }
            if(this.serviceLocationStatus == "homePageMenu") {
              response.Data.ServiceLists = response.Data.ServiceLists.filter(service => service.ShowOnHomePage);
          }
              this.serviceResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                ServiceResponseArrayModel
              );
                           
              if(this.serviceLocationStatus == "serviceFrontView"||"serviceAdminView"){
                this.servicesList = this.serviceResponseArrayModel.ServiceLists.slice();
                let tempArray = [];
                for(let i=0; i < this.servicesList.length; i++){
                  if(this.servicesList[i].ServiceType == 3 || this.servicesList[i].InternalService){
                    tempArray.push(this.servicesList[i].id);
                  }
                }
                // tempArray.reverse();
                
                for(let j=0; j < tempArray.length; j++){
                  for(let i = 0; i< this.servicesList.length; i++){
                    if(tempArray[j] == this.servicesList[i].id){
                      this.servicesList.splice(i,1);
                    }
                  }
                }
              }

              if (
                this.serviceResponseArrayModel.ServiceLists &&
                this.serviceResponseArrayModel.ServiceLists.length
              ) {
                this.checkServiceCount.emit(
                  this.serviceResponseArrayModel.ServiceLists.length
                );
              } else {
                this.checkServiceCount.emit(0);
              }
              this.globalService.showLoader = false;
            } else {
              this.globalService.showLoader = false;
              this.serviceResponseArrayModel.ServiceLists =
                ServiceResponseModel[""];
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(error);
          }
        );
    } else {
      if(data.serviceLocationStatus == 'serviceManagePage'){
        data.Data = 'serviceManagePage';
      }
      
      this.masterService
        .showServiceListsAdmin(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              this.thresholdServiceId = response.Data.servicesWithThreshold;
              this.serviceResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                ServiceResponseArrayModel
              );
              if (this.serviceResponseArrayModel.CountLists) {
                this.length = this.serviceResponseArrayModel.CountLists;
              }
              if (
                this.serviceResponseArrayModel.ServiceLists &&
                this.serviceResponseArrayModel.ServiceLists.length
              ) {
                this.checkServiceCount.emit(
                  this.serviceResponseArrayModel.ServiceLists.length
                );
              } else {
                this.checkServiceCount.emit(0);
              }
            } else {
              this.globalService.showLoader = false;
              this.serviceResponseArrayModel.ServiceLists =
                ServiceResponseModel[""];
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }

  thresholdCheck(id){
    if(this.thresholdServiceId.length > 0){
      for(let i=0; this.thresholdServiceId.length > i; i++){        
        if(this.thresholdServiceId[i].serviceId == id){
          return true;
        }  
      }
      return false;
    }else{
      return false;      
    }
  }

  tabChanged(tabChangeEvent){
    this.servicesList = this.serviceResponseArrayModel.ServiceLists.slice();
    let tempArray = [];
    if(this.serviceLocationStatus== "serviceFrontView"){
      if(tabChangeEvent.index == 0){
        for(let i=0; i < this.servicesList.length; i++){
          if(this.servicesList[i].ServiceType == "3"){
            tempArray.push(this.servicesList[i].id);
          }
        }
      }else if(tabChangeEvent.index == 1){
        for(let j=0; j < this.servicesList.length; j++){
          if(this.servicesList[j].ServiceType == "1" || this.servicesList[j].ServiceType == "2"){
            tempArray.push(this.servicesList[j].id);
          }
        }
      }
    }else if(this.serviceLocationStatus== "serviceAdminView"){
      if(tabChangeEvent.index == 0){
        for(let i=0; i < this.servicesList.length; i++){
          if(this.servicesList[i].ServiceType == "3" || this.servicesList[i].InternalService){
            tempArray.push(this.servicesList[i].id);
          }
        }
      }else if(tabChangeEvent.index == 1){
        for(let j=0; j < this.servicesList.length; j++){
          if(this.servicesList[j].ServiceType == "1" || this.servicesList[j].ServiceType == "2" || this.servicesList[j].InternalService){
            tempArray.push(this.servicesList[j].id);
          }
        }
      }else if(tabChangeEvent.index == 2){
        for(let j=0; j < this.servicesList.length; j++){
          if(this.servicesList[j].InternalService !== true){
            tempArray.push(this.servicesList[j].id);
          }
        }
      }
    }
       
    for(let j=0; j < tempArray.length; j++){
      for(let i = 0; i< this.servicesList.length; i++){
        if(tempArray[j] == this.servicesList[i].id){
          this.servicesList.splice(i,1);
        }
      }
    } 
  }

  deactivateFromHome(id) {
    let lists = this.serviceResponseArrayModel.ServiceLists;

    let findIndex = lists.findIndex(list => list.id === id);

    this.globalService.showLoader = true;
    this.masterService
      .updateServiceHomeStatus({
        Lang: this.masterService.getLangSaveProperty(),
        Id: id
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.serviceResponseArrayModel.ServiceLists.splice(findIndex, 1);
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  getPaginatorData(event) {
       this.showServiceLists({
      Data: null,
      pageIndex: event.pageSize * event.pageIndex,
      pageSize: event.pageSize
    });
  }

  detail(id) {
    this.detailServiceViewEmit.emit(id);
  }

  edit(id) {
    this.editServiceViewEmit.emit(id);
  }

  view(event) {
    this.frontView = "view";
    document.getElementById("listId").classList.remove("active");

    if (!event.currentTarget.classList.contains("active")) {
      event.currentTarget.className =
        "active " + event.currentTarget.attributes.class.value;
    }

    // event.target.offsetParent.firstElementChild.className = "active";
    // if (
    //   event.target.parentElement.parentElement.classList.contains(
    //     "mat-checkbox-checked"
    //   )
    // ) {
    //   event.target.offsetParent.firstElementChild.classList.remove("active");
    // } else {
    //   event.target.offsetParent.firstElementChild.className = "active";
    // }
  }
  list(event) {
    this.frontView = "list";
    document.getElementById("viewId").classList.remove("active");
    if (!event.currentTarget.classList.contains("active")) {
      event.currentTarget.className =
        "active " + event.currentTarget.attributes.class.value;
    }
  }
}