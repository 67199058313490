<app-loader [ShowLoader]="globalService.showLoader"></app-loader>

<form class="form-wrapper manage-branding-form-wrapper edit_staff" [formGroup]="form" (ngSubmit)="save(form.value)">
    <div class="">
        <div class="modal-header d-flex align-items-center justify-content-between  ">
            <h4 class="block-title mb-0">{{ 'Staff Information'|translate }}</h4>
            <button class="material-icons" mat-dialog-close>close</button>
        </div>
        <div class="modal-content p4">
            <div class="row m-0 pt-4">
                <div class="col-lg-3 col-md-3 staff_image_wrapper">
                    <div class="uload-image">
                        <div class="upload">
                            <i class="material-icons">contacts</i>
                            <span>{{ 'UploadImage'|translate }}</span>
                            <file-upload (imageUpload)="imageUpload($event)" [locationStatus]="parentLocationStatus" [imgUrl]="imgUrl" [uploadFolder]="'staff'">
                            </file-upload>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-9">
                    <div class="row">
                        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <mat-label>{{'First name'|translate}}</mat-label>
                            <input matInput type="text" formControlName="FirstName" placeholder="Bob">
                            <small *ngIf="form.controls['FirstName'].hasError('required') && form.controls['FirstName'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <mat-label>{{'Last name'|translate}}</mat-label>
                            <input matInput type="text" formControlName="LastName" placeholder="James">
                            <small *ngIf="form.controls['LastName'].hasError('required') && form.controls['LastName'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <mat-label>{{'Department'|translate}}</mat-label>
                            <mat-select formControlName="DepartmentId">
                                <mat-option *ngFor="let item of departmentListArray" [value]="item.id">
                                    <ng-container *ngIf="item.Name && item.Name.length;else department">{{item.Name[0].Value}}
                                    </ng-container>
                                    <ng-template #department>{{'NotAvailable'|translate}}</ng-template>
                                </mat-option>
                            </mat-select>
                            <small *ngIf="form.controls['DepartmentId'].hasError('required') && form.controls['DepartmentId'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                        </mat-form-field>
                    </div>

                    <div class="row">

                        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <mat-label>{{'Role'|translate}}</mat-label>
                            <mat-select formControlName="RoleId">
                                <mat-option *ngFor="let item of roleListArray" [value]="item.id">
                                    <ng-container *ngIf="item.RoleName && item.RoleName.length;else RoleName">{{item.RoleName[0].Value}}
                                    </ng-container>
                                    <ng-template #RoleName>{{'NotAvailable'|translate}}</ng-template>
                                </mat-option>
                            </mat-select>
                            <small *ngIf="form.controls['RoleId'].hasError('required') && form.controls['RoleId'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <mat-label>{{'Title'|translate}}</mat-label>
                            <input matInput type="text" formControlName="Designation" placeholder="Title">
                            <small *ngIf="form.controls['Designation'].hasError('required') && form.controls['Designation'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                        </mat-form-field>
                    </div>

                    <div class="row contactInfowrap">
                        <h4 class="block-title col-lg-12">{{ 'Contactinfo'|translate }}</h4>
                        <div class="clear20"></div>
                        <mat-form-field class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <mat-label>{{'Email'|translate}}</mat-label>
                            <input matInput type="text" formControlName="Email" placeholder="Enter your email">
                            <small *ngIf="form.controls['Email'].hasError('required') && form.controls['Email'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                            <small *ngIf="form.controls['Email'].hasError('email')" class="text-danger">{{ 'Validation.EmailRequired'|translate }}</small>
                            <small class="text-danger" *ngIf="form.controls['Email'].hasError('checkEmail')">{{ 'AlreadyExists'|translate }}</small>
                        </mat-form-field>

                        <mat-form-field class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <mat-label>{{'Phone number'|translate}}</mat-label>
                            <input matInput type="text" formControlName="PhoneNumber" placeholder="Enter your phone number" maxLength="16">
                            <small *ngIf="form.controls['PhoneNumber'].hasError('required') && form.controls['PhoneNumber'].touched" class="text-danger">{{ 'Validation.Required'|translate }}</small>
                            <small class="text-danger" *ngIf="form.controls['PhoneNumber'].hasError('pattern') && (form.controls['PhoneNumber'].dirty || form.controls['PhoneNumber'].touched)">{{'Validation.OnlyNumbersAllowed'|translate}}</small>
                        </mat-form-field>
                    </div>

                    <div class="row createAcount">
                        <mat-checkbox class="col-lg-12" formControlName="CreateAccount">Create an account?</mat-checkbox>
                    </div>

                    <!-- <div class="row createAcount" *ngIf="editId">
                        <span class="yellow-text float-right" *ngIf="resendInvitation"><i
                class="material-icons">report_problem</i>{{'Invitepending'|translate}}</span>
                        <a href="javascript:;" [class.disabled]="isDisabled()" (click)="!isDisabled() && resend(editId)" *ngIf="resendInvitation">{{ 'ResendInvitation'|translate }}</a>
                        <ng-container *ngIf="!activateResend"><span>{{counter$ | async}}</span></ng-container>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="form-footer px-3">
        <div class="button-wrapper">
            <button class="btn btn-link" type="button" mat-dialog-close>
        <i class="material-icons">clear</i>
        {{'Cancel'|translate}}
      </button>
            <button class="btn btn-primary" type="button" (click)="save(form)" [disabled]="!form.valid">
        <i class="material-icons">check</i>
        <span>{{ "Save" | translate }} </span>
      </button>

        </div>
    </div>
</form>