import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { JwtService } from "../services/jwt.service";
@Injectable()
export class HotelAuthGuard implements CanActivate {
  constructor(private jwtService: JwtService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const userRole = this.jwtService.getUserRole();
    

    if (userRole) {
      if (userRole == "admin" || userRole == "staff") {
        return true;
      } else if (userRole == "default") {
        this.router.navigate([`/portal/${this.jwtService.getUserRole()}/hotelProfile`]);
        return true;
      } else {
        this.jwtService.deleteToken("portal");
        return false;
      }
    } else {
      this.jwtService.deleteToken("portal");
      return false;
    }
  }
}
