<!-- //Home Page -->
<!-- <app-loader [ShowLoader]="globalService.showLoader"></app-loader>
 -->
<!-- Home list -->
<div class="home-page row" *ngIf="locationStatus == 'home'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6  mb-4 col-xs-12" *ngFor="let obj of tourResponseArrayModel.TourLists| slice:0:4;">
        <div class="wrapper-list-thumb">
            <div class="room-img-wrapper">
                <a [routerLink]="['/tours-events',obj.id]" *ngIf="obj.TourImages && obj.TourImages.length">
                    <img [src]="obj.TourImages[0]" />
                    <ng-template #otherImage>
                        <img src="assets/images/No_Image_Available.jpg" />
                    </ng-template>
                </a>
                <span *ngIf="!globalService.validateIsEmpty(obj.TourAvailability)">
          <ng-container *ngIf="obj.DateTime == '1'">
            <span class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability">

              <ng-container *ngIf="tour.DaysNames">
                <span *ngFor="let item of tour.DaysNames" class="date-from">
                  <ng-container *ngIf="item.Monday">
                    {{'Mon'}}
                  </ng-container>
                  <ng-container *ngIf="item.Tuesday">
                    {{'Tue'}}
                  </ng-container>
                  <ng-container *ngIf="item.Wednesday">
                    {{'Wed'}}
                  </ng-container>
                  <ng-container *ngIf="item.Thursday">
                    {{'Thu'}}
                  </ng-container>
                  <ng-container *ngIf="item.Friday">
                    {{'Fri'}}
                  </ng-container>
                  <ng-container *ngIf="item.Saturday">
                    {{'Sat'}}
                  </ng-container>
                  <ng-container *ngIf="item.Sunday">
                    {{'Sun'}}
                  </ng-container>
                </span>
                </ng-container>

                <span class="time-from">
                <ng-container *ngIf="tour.FromTime">
                  {{tour.FromTime|timeFormat}} -
                </ng-container>
                <ng-container *ngIf="tour.ToTime">
                  {{tour.ToTime|timeFormat}}
                </ng-container>
              </span>
                </span>
                </ng-container>
                <ng-container *ngIf="obj.DateTime == '2'">

                    <p class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability| slice:0:1">
                        <span class="date-from">
                <ng-container *ngIf="tour.DateFrom">
                  {{tour.DateFrom|dateFormat}} -
                </ng-container>
                <ng-container *ngIf="tour.DateFrom">
                  {{tour.DateTo|dateFormat}}
                </ng-container>
              </span>
                        <span class="time-from">
                <ng-container *ngIf="tour.TimeFrom">
                  {{tour.TimeFrom|timeFormat}} -
                </ng-container>
                <ng-container *ngIf="tour.TimeTo">
                  {{tour.TimeTo|timeFormat}}
                </ng-container>
              </span>
                    </p>
                </ng-container>
                </span>
            </div>
            <div class="list-thumb-content">
                <p class="type-info secondary-text" *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                    <span>{{obj?.Department?.Department?.[0]?.Value}}</span>
                </p>
                <ng-template #otherDepartment>
                    <p class="type-info primary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="type-info primary-text mb-2" *ngIf="!globalService.validateIsEmpty(obj.TourName);else otherTourName">
                    {{obj.TourName[0].Value}}</p>
                <ng-template #otherTourName>
                    <p class="type-info primary-text mb-2">{{ 'NotAvailable'|translate }}</p>
                </ng-template>

                <!-- <span>
          <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
            class="star_rating_outer"></star-rating> 9 Reviews
        </span> -->

            </div>
        </div>
    </div>
</div>

<!-- //Home list -->

<!-- Home Page Menu Page -->
<div class="home-page row" *ngIf="locationStatus == 'homePageMenu'">
    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 mb-4 col-xs-12" *ngFor="let obj of tourResponseArrayModel.TourLists">
        <div class="wrapper-list-thumb position-relative wrapper-list-thumb-manage">
            <div class="delete-image"> <i class="material-icons" (click)="deactivateFromHome(obj.id)" [appAccessCheck]="'homepage-menu'" [givenButton]="'delete'">delete</i></div>
            <div class="room-img-wrapper">
                <span *ngIf="obj.TourImages && obj.TourImages.length">
          <img [src]="obj.TourImages[0]" />
          <ng-template #otherImage>
            <img src="assets/images/No_Image_Available.jpg" />
          </ng-template>
        </span>
                <span *ngIf="!globalService.validateIsEmpty(obj.TourAvailability)">
          <ng-container *ngIf="obj.DateTime == '1'">
            <span class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability">

              <ng-container *ngIf="tour.DaysNames">
                <span *ngFor="let item of tour.DaysNames" class="date-from">
                  <ng-container *ngIf="item.Monday">
                    {{'Mon'}}
                  </ng-container>
                  <ng-container *ngIf="item.Tuesday">
                    {{'Tue'}}
                  </ng-container>
                  <ng-container *ngIf="item.Wednesday">
                    {{'Wed'}}
                  </ng-container>
                  <ng-container *ngIf="item.Thursday">
                    {{'Thu'}}
                  </ng-container>
                  <ng-container *ngIf="item.Friday">
                    {{'Fri'}}
                  </ng-container>
                  <ng-container *ngIf="item.Saturday">
                    {{'Sat'}}
                  </ng-container>
                  <ng-container *ngIf="item.Sunday">
                    {{'Sun'}}
                  </ng-container>
                </span>
                </ng-container>

                <span class="time-from">
                <ng-container *ngIf="tour.FromTime">
                  {{tour.FromTime|timeFormat}} -
                </ng-container>
                <ng-container *ngIf="tour.ToTime">
                  {{tour.ToTime|timeFormat}}
                </ng-container>
              </span>
                </span>
                </ng-container>
                <ng-container *ngIf="obj.DateTime == '2'">

                    <p class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability| slice:0:1">
                        <span class="date-from">
                <ng-container *ngIf="tour.DateFrom">
                  {{tour.DateFrom|dateFormat}} -
                </ng-container>
                <ng-container *ngIf="tour.DateFrom">
                  {{tour.DateTo|dateFormat}}
                </ng-container>
              </span>
                        <span class="time-from">
                <ng-container *ngIf="tour.TimeFrom">
                  {{tour.TimeFrom|timeFormat}} -
                </ng-container>
                <ng-container *ngIf="tour.TimeTo">
                  {{tour.TimeTo|timeFormat}}
                </ng-container>
              </span>
                    </p>
                </ng-container>
                </span>
            </div>
            <div class="list-thumb-content">
                <p class="title-info primary-text mb-2" *ngIf="!globalService.validateIsEmpty(obj.TourName);else otherTourName">
                    {{obj.TourName[0].Value}}</p>
                <ng-template #otherTourName>
                    <p class="title-info primary-text mb-2">{{ 'NotAvailable'|translate }}</p>
                </ng-template>
                <p class="type-info secondary-text" *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else  otherDepartment">
                    <span>{{obj?.Department?.Department?.[0]?.Value}}</span></p>
                <ng-template #otherDepartment>
                    <p class="type-info primary-text">{{ 'NotAvailable'|translate }}</p>
                </ng-template>

                <!-- <span>
          <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
            class="star_rating_outer"></star-rating> 9 Reviews
        </span> -->
            </div>
        </div>
    </div>
</div>



<!---Home Front View-->
<div class="room-front-view " *ngIf="locationStatus == 'frontView'">
    <div class="row" *ngIf="!globalService.validateIsEmpty(tourResponseArrayModel.TourLists) && tourResponseArrayModel.TourLists.length else otherLists">

        <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 mb-4 col-xs-12" *ngFor="let obj of tourResponseArrayModel.TourLists">
            <div class="wrapper-list-thumb">
                <div class="room-img-wrapper">
                    <a [routerLink]="['/tours-events',obj.id]">
                        <img [src]="obj.TourImages[0]" *ngIf="obj.TourImages && obj.TourImages.length;else otherImage">
                        <ng-template #otherImage>
                            <img src="assets/images/No_Image_Available.jpg" />
                        </ng-template>
                    </a>
                    <span *ngIf="!globalService.validateIsEmpty(obj.TourAvailability)">
            <ng-container *ngIf="obj.DateTime == '1'">
              <span class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability">

                <ng-container *ngIf="tour.DaysNames">
                  <span *ngFor="let item of tour.DaysNames" class="date-from">
                    <ng-container *ngIf="item.Monday">
                      {{'Mon'}}
                    </ng-container>
                    <ng-container *ngIf="item.Tuesday">
                      {{'Tue'}}
                    </ng-container>
                    <ng-container *ngIf="item.Wednesday">
                      {{'Wed'}}
                    </ng-container>
                    <ng-container *ngIf="item.Thursday">
                      {{'Thu'}}
                    </ng-container>
                    <ng-container *ngIf="item.Friday">
                      {{'Fri'}}
                    </ng-container>
                    <ng-container *ngIf="item.Saturday">
                      {{'Sat'}}
                    </ng-container>
                    <ng-container *ngIf="item.Sunday">
                      {{'Sun'}}
                    </ng-container>
                  </span>
                    </ng-container>

                    <span class="time-from">
                  <ng-container *ngIf="tour.FromTime">
                    {{tour.FromTime|timeFormat}} -
                  </ng-container>
                  <ng-container *ngIf="tour.ToTime">
                    {{tour.ToTime|timeFormat}}
                  </ng-container>
                </span>
                    </span>
                    </ng-container>
                    <ng-container *ngIf="obj.DateTime == '2'">

                        <p class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability| slice:0:1">
                            <span class="date-from">
                  <ng-container *ngIf="tour.DateFrom">
                    {{tour.DateFrom|dateFormat}} -
                  </ng-container>
                  <ng-container *ngIf="tour.DateFrom">
                    {{tour.DateTo|dateFormat}}
                  </ng-container>
                </span>
                            <span class="time-from">
                  <ng-container *ngIf="tour.TimeFrom">
                    {{tour.TimeFrom|timeFormat}} -
                  </ng-container>
                  <ng-container *ngIf="tour.TimeTo">
                    {{tour.TimeTo|timeFormat}}
                  </ng-container>
                </span>
                        </p>
                    </ng-container>
                    </span>
                </div>
                <div class="list-thumb-content">
                    <p class="title-info primary-text" *ngIf="!globalService.validateIsEmpty(obj.TourName);else otherTourName">
                        {{obj.TourName[0].Value}}</p>
                    <ng-template #otherTourName>
                        <p class="title-info primary-text mb-2">{{ 'NotAvailable'|translate }}</p>
                    </ng-template>
                    <p class="type-info secondary-text" *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                        <span>{{obj?.Department?.Department?.[0]?.Value}}</span></p>
                    <ng-template #otherDepartment>
                        <p class="type-info primary-text">{{ 'NotAvailable'|translate }}</p>
                    </ng-template>
                    <!-- <span>
            <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
              class="star_rating_outer"></star-rating> 9 Reviews
          </span> -->
                </div>
            </div>
        </div>

        <div class="pagination" *ngIf="tourResponseArrayModel.TourLists">
            <span *ngIf="tourResponseArrayModel.TourLists.length">
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
        </mat-paginator>
      </span>
        </div>
    </div>

</div>
<!---//Home Front View-->

<!---Restaurant Manage Page-->
<div class="room-manage-page" *ngIf="locationStatus == 'tourManagePage'">
    <div class="content-section-outer">
        <div class="content-wrapper">
            <div class="main-content-wrapper row">
                <!-- Home list -->
                <div class="home-page row" *ngIf="!globalService.validateIsEmpty(tourResponseArrayModel.TourLists)">
                    <div class="gallery-item-list col-lg-3 col-md-4 col-sm-6 mb-4 col-xs-12" *ngFor="let obj of tourResponseArrayModel.TourLists">
                        <div class="wrapper-list-thumb">
                            <div class="room-img-wrapper">

                                <img [src]="obj.TourImages[0]" *ngIf="obj.TourImages && obj.TourImages.length;else otherImage" />
                                <ng-template #otherImage>
                                    <img src="assets/images/No_Image_Available.jpg" />
                                </ng-template>

                                <span *ngIf="!globalService.validateIsEmpty(obj.TourAvailability)">
                  <ng-container *ngIf="obj.DateTime == '1'">
                    <span class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability">

                      <ng-container *ngIf="tour.DaysNames">
                        <span *ngFor="let item of tour.DaysNames" class="date-from">
                          <ng-container *ngIf="item.Monday">
                            {{'Mon'}}
                          </ng-container>
                          <ng-container *ngIf="item.Tuesday">
                            {{'Tue'}}
                          </ng-container>
                          <ng-container *ngIf="item.Wednesday">
                            {{'Wed'}}
                          </ng-container>
                          <ng-container *ngIf="item.Thursday">
                            {{'Thu'}}
                          </ng-container>
                          <ng-container *ngIf="item.Friday">
                            {{'Fri'}}
                          </ng-container>
                          <ng-container *ngIf="item.Saturday">
                            {{'Sat'}}
                          </ng-container>
                          <ng-container *ngIf="item.Sunday">
                            {{'Sun'}}
                          </ng-container>
                        </span>
                                </ng-container>

                                <span class="time-from">
                        <ng-container *ngIf="tour.FromTime">
                          {{tour.FromTime|timeFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.ToTime">
                          {{tour.ToTime|timeFormat}}
                        </ng-container>
                      </span>
                                </span>
                                </ng-container>
                                <ng-container *ngIf="obj.DateTime == '2'">

                                    <p class="secondary-text date-time-list mb-2" *ngFor="let tour of obj.TourAvailability| slice:0:1">
                                        <span class="date-from">
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateFrom|dateFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.DateFrom">
                          {{tour.DateTo|dateFormat}}
                        </ng-container>
                      </span>
                                        <span class="time-from">
                        <ng-container *ngIf="tour.TimeFrom">
                          {{tour.TimeFrom|timeFormat}} -
                        </ng-container>
                        <ng-container *ngIf="tour.TimeTo">
                          {{tour.TimeTo|timeFormat}}
                        </ng-container>
                      </span>
                                    </p>
                                </ng-container>
                                </span>
                            </div>
                            <div class="list-thumb-content">
                                <p class="title-info primary-text mb-2" *ngIf="!globalService.validateIsEmpty(obj.TourName);else otherTourName">
                                    {{obj.TourName[0].Value}}</p>

                                <ng-template #otherTourName>
                                    <p class="title-info primary-text mb-2">{{ 'NotAvailable'|translate }}</p>
                                </ng-template>
                                <p class="type-info secondary-text" *ngIf="!globalService.validateIsEmpty(obj?.Department?.Department);else otherDepartment">
                                    <span>{{obj?.Department?.Department?.[0]?.Value}}</span>
                                </p>
                                <ng-template #otherDepartment>
                                    <p class="type-info secondary-text">{{ 'NotAvailable'|translate }}</p>
                                </ng-template>
                                <!-- <span>
                  <star-rating value="5" checkedcolor="#f8b833" uncheckedcolor="#d0d0d4" size="14px" readonly="false"
                    (rate)="onRate($event)" class="star_rating_outer"></star-rating> 9 Reviews
                </span> -->
                            </div>


                            <div class="button-section">
                                <button type="button" class="btn btn-primary" (click)="detail(obj.id)"  [appAccessCheck]="'tours'"[givenButton]="'view'">
                  <i class="material-icons">remove_red_eye</i> View detail
                </button>
                                <button class="btn btn-secondary" type="type" (click)="edit(obj.id)" [appAccessCheck]="'tours'"[givenButton]="'edit'">
                  <i class="material-icons">create</i> Edit
                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination" *ngIf="tourResponseArrayModel.TourLists">
                    <span *ngIf="tourResponseArrayModel.TourLists.length">
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent = getPaginatorData($event)" *ngIf="length > 0">
            </mat-paginator>
          </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!---//Restaurant Manage Page-->