import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { Router } from "@angular/router";
import { MasterService } from "../../services/master.service";
import { SharedService } from "src/app/shared.service";
import { GlobalService } from "../../services/global.service";
import { takeUntil } from "rxjs/operators";
import {
  TourResponseArrayModel,
  DateModel
} from "../../models/common-request-response";
import { JsonConvert, ValueCheckingMode } from "json2typescript";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import _moment from "moment";
import { TranslateService } from "@ngx-translate/core";
// tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';
const moment = _moment;
@Component({
  selector: "app-hotel-tours",
  templateUrl: "./hotel-tours.component.html",
  styleUrls: ["./hotel-tours.component.scss"],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class HotelToursComponent implements OnInit {
  headerLangHotelChangeEvent$;

  public tourResponseArrayModel: TourResponseArrayModel = new TourResponseArrayModel();
  public dateModel: DateModel = new DateModel();

  @Input() locationStatus: string;
  @Output() detailViewEmit = new EventEmitter();
  @Output() editViewEmit = new EventEmitter();
  @Output() checkTourCount = new EventEmitter();

  innerBannerShow: boolean = false;
  viewAllShow: boolean = true;
  headdingTitle = this.translate.instant("HotelTours");

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  // MatPaginator Output
  pageEvent: PageEvent;

  //Loader
  title = "materialApp";
  color = "primary";
  mode = "indeterminate";
  showLoader: boolean = true;
  jsonConvert: JsonConvert = new JsonConvert();
  public dateArray: any;

  constructor(
    private router: Router,
    private masterService: MasterService,
    private sharedService: SharedService,
    public globalService: GlobalService,
    public translate : TranslateService
  ) {}

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  ngOnInit() {
    // API Requests For Different Locations

    if (
      this.locationStatus == "home" ||
      this.locationStatus == "homePageMenu"
    ) {
      this.showToursLists({
        Data: "home",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    if (this.locationStatus == "frontView") {
      this.showToursLists({
        Data: "front",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    if (this.locationStatus == "tourManagePage") {
      this.showToursLists({
        Data: null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    this.headerLangHotelChangeEvent$ = this.sharedService.headerLangChangeEvent.subscribe(
      (data: string) => {
        // API Requests For Different Locations
        if (
          this.locationStatus == "home" ||
          this.locationStatus == "homePageMenu"
        ) {
          this.globalService.showLoader = false;
          this.showToursLists({
            Data: "home",
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
          });
        }

        if (this.locationStatus == "frontView") {
          this.showToursLists({
            Data: "front",
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
          });
        }

        if (this.locationStatus == "tourManagePage") {
          this.showToursLists({
            Data: null,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize
          });
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.headerLangHotelChangeEvent$) {
      this.headerLangHotelChangeEvent$.unsubscribe();
    }
  }

  showToursLists(data) {
    // jsonConvert.operationMode = OperationMode.LOGGING; // print some debug data
    // jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // never allow null
    this.globalService.showLoader = true;
          if (
      this.locationStatus == "frontView" ||
      this.locationStatus == "home" ||
      this.locationStatus == "homePageMenu"
    ) {
      this.masterService
        .showToursLists(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              this.tourResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                TourResponseArrayModel
              );

              if (
                !this.globalService.validateIsEmpty(
                  this.tourResponseArrayModel.TourLists[0].TourAvailability
                )
              ) {
                this.dateArray = this.tourResponseArrayModel.TourLists[0].TourAvailability;
                this.dateArray.map(item => {
                  let datecheck = moment(item.DateFrom);
                });
              }

              if (
                this.tourResponseArrayModel.TourLists &&
                this.tourResponseArrayModel.TourLists.length
              ) {
                this.checkTourCount.emit(
                  this.tourResponseArrayModel.TourLists.length
                );
              } else {
                this.checkTourCount.emit(0);
              }
            } else {
              this.globalService.showLoader = false;
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(error);
          }
        );
    } else {
      this.masterService
        .showToursListsAdmin(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
                           this.tourResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                TourResponseArrayModel
              );
                           if (
                this.tourResponseArrayModel.TourLists &&
                this.tourResponseArrayModel.TourLists.length
              ) {
                this.checkTourCount.emit(
                  this.tourResponseArrayModel.TourLists.length
                );
              } else {
                this.checkTourCount.emit(0);
              }
            } else {
              this.globalService.showLoader = false;
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(error);
          }
        );
    }
  }

  deactivateFromHome(id) {
    let lists = this.tourResponseArrayModel.TourLists;

    let findIndex = lists.findIndex(list => list.id === id);

    this.globalService.showLoader = true;
    this.masterService
      .updateTourHomeStatus({
        Lang: this.masterService.getLangSaveProperty(),
        Id: id
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.tourResponseArrayModel.TourLists.splice(findIndex, 1);
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  getPaginatorData(event) {
       this.showToursLists({
      Data: null,
      pageIndex: event.pageSize * event.pageIndex,
      pageSize: event.pageSize
    });
  }

  detail(id) {
    this.detailViewEmit.emit(id);
  }

  edit(id) {
    this.editViewEmit.emit(id);
  }
}
