import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/core/services/api.service";
import { ResultModel } from "src/app/core/models/response/base.model";

@Injectable()
export class HotelAuthService {
  constructor(private apiService: ApiService) {}

  hotelAuth(reqModel): Observable<ResultModel> {
    const route = "/hotel/hotelLoginAuth";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  getAdminPermissions(reqModel): Observable<ResultModel> {
    const route = "/hotel/getStaffPermissions";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  gethotelProfile(reqModel): Observable<ResultModel> {
    const route = "/hotel/gethotelProfile";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getAllInventoryCount(reqModel): Observable<ResultModel> {
    const route = "/allInventoryRequest";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  getAllOfferingsCount(reqModel): Observable<ResultModel> {
    const route = "/hotel/getAllThreshold";
    return this.apiService.get<ResultModel>(route, reqModel);
  }

  hotelProfileUpdate(reqModel): Observable<ResultModel> {
    const route = "/hotel/hotelProfileUpdate";
    return this.apiService.post<ResultModel>(route, reqModel);
  }

  deleteProfileImage(reqModel): Observable<ResultModel> {
    const route = "/hotel/deleteProfileImage";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
  
  adminEmailActivation(reqModel): Observable<ResultModel> {
    const route = "/hotel/adminEmailActivation";
    return this.apiService.post<ResultModel>(route, reqModel);
  }
}
