<app-loader [ShowLoader]="globalService.showLoader"></app-loader>

<form [formGroup]="addNewAmmenties" class="form-wrapper">
    <div class="modal-header d-flex align-items-center justify-content-between p-4">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h3 class="mb-0" *ngIf="insertStatus">{{ 'Addnewamentie'|translate}}</h3>
                <h3 class="mb-0" *ngIf="updateStatus">Update amenity</h3>
            </div>
            <div class="lang-drop-menu">
                <span>{{ 'EditTextFor'|translate }}:</span>
                <app-language-save [popupStatus]='setPopupStatus' (selectedLang)="switchLanguage($event)"></app-language-save>
            </div>
        </div>
        <button mat-dialog-close class="btn-outline modal-close modal-cancel cancel-btn"><i class="material-icons">close</i> </button>
    </div>
    <div class="modal-body-content p-4">
        <mat-form-field class="col-lg-12 pad0">
            <mat-label>{{'Title'|translate}}</mat-label>
            <input matInput type="text" formControlName="amentiesName" placeholder="Enter name here">
        </mat-form-field>


        <mat-form-field class="col-lg-4 col-md-5 col-sm-6 col-xs-12 pad0 mb-0">
            <mat-label>Select icon</mat-label>
            <mat-select formControlName="AmenitiesIcon">
                <mat-option>
                    <ngx-mat-select-search [formControl]="amenitiesIconFilterCtrl" [placeholderLabel]='placeholderLabel'>
                    </ngx-mat-select-search>
                </mat-option>

                <mat-select-trigger class="d-flex align-items-center">
                    <mat-icon class="mr-2">{{ f.AmenitiesIcon.value }}</mat-icon>
                    {{ f.AmenitiesIcon.value }}
                </mat-select-trigger>
                <mat-option *ngFor="let type of filteredAmentiesIconModel | async" [value]="type.ligature">
                    <!-- <i class="material-icons">{{ type.ligature }}</i> -->
                    <mat-icon>{{ type.ligature }}</mat-icon>
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="modal-footer-content d-flex align-items-center justify-content-end p-4">
        <a (click)="closeModal()" mat-dialog-close class="btn-outline modal-close modal-cancel cancel-btn d-flex align-items-center mr-4" [appAccessCheck]="'rooms'" [givenButton]="'cancel'">
            <i class="material-icons mr-1">close</i>{{'Cancel'|translate}}
        </a>
        <div>
            <a (click)="add(addNewAmmenties)" class="waves-effect waves-light btn btn-primary modal-close modal-trigger text-white" *ngIf="insertStatus" [appAccessCheck]="'rooms'" [givenButton]="'add'">{{'Submit'|translate}}</a>
            <a (click)="add(addNewAmmenties)" class="waves-effect waves-light btn btn-primary modal-close modal-trigger text-white" *ngIf="updateStatus" [appAccessCheck]="'rooms'" [givenButton]="'update'">{{'Submit'|translate}}</a>
        </div>
    </div>
</form>