import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { MasterService } from "../../services/master.service";
import { SharedService } from "src/app/shared.service";
import { takeUntil } from "rxjs/operators";
import { GlobalService } from "../../services/global.service";
import { RoomResponseArrayModel } from "../../models/response/room-response";
import { PageEvent } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import _moment from "moment";
const moment = _moment;

@Component({
  selector: "app-hotel-rooms",
  templateUrl: "./hotel-rooms.component.html",
  styleUrls: ["./hotel-rooms.component.scss"]
})
export class HotelRoomsComponent implements OnInit {
  @Input() roomLocationStatus: string;
  headerLangHotelChangeEvent$;
  @Input() searchData;
  @Input() change;
  public roomResponseArrayModel: RoomResponseArrayModel = new RoomResponseArrayModel();

  @Output() detailRoomViewEmit = new EventEmitter();
  @Output() editRoomViewEmit = new EventEmitter();
  @Output() checkRoomCount = new EventEmitter();
  today: Date = new Date();

  innerBannerShow: boolean = false;
  viewAllShow: boolean = true;
  headdingTitle = this.translate.instant("HotelRooms");

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  public tillDate = moment().add(3,'days');
  public showDate = this.tillDate.toString().slice(0,10);
  public roomBookingData;
  public checkout;
  public currencyLogo = window.localStorage["CurrencyLogo"];
  public currencyAbri;
  // MatPaginator Output  
  pageEvent: PageEvent;

  //Loader
  title = "materialApp";
  color = "primary";
  mode = "indeterminate";
  showLoader: boolean = false;

  constructor(
    private router: Router,
    private masterService: MasterService,
    private sharedService: SharedService,
    public globalService: GlobalService,
    public translate: TranslateService
  ) {}

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  ngOnInit() {
    if(!this.currencyLogo){
      this.getHotelsName();
    }
    
    if (this.router.url == "/") {
      this.innerBannerShow = false;
      this.viewAllShow = true;
    }
    if (this.router.url == "/rooms") {
      this.checkout = this.searchData.checkOut;
      this.globalService.showLoader = true;
      this.innerBannerShow = true;
      this.viewAllShow = false;
    }
    // API Requests For Different Locations
    this.checkRoomLocation();
    this.headerLangHotelChangeEvent$ = this.sharedService.headerLangChangeEvent.subscribe(
      (data: string) => {
        this.checkRoomLocation();
      }
    );
  }

  getHotelsName(){
       const data={};
      this.masterService.getHotelName(data).subscribe(response =>{
       if (response){
         window.localStorage["CurrencyLogo"] = response[0].symbol
         window.localStorage["CurrencyAbri"] = response[0].abbreviation
         window.localStorage["Currency"] = response[0].currency
         this.currencyLogo = response[0].symbol;
         this.currencyAbri = response[0].abbreviation;
         this.globalService.setHotelNameUpdate(response[1]);
       }error => {
         this.globalService.handleApiError(error);
       }
      });
   }

  ngOnChanges(){
    if(this.roomLocationStatus=='noResultFound'){
      this.roomLocationStatus = 'roomSearch';
    }
    this.ngOnInit();
  }

  ngOnDestroy() {
    if (this.headerLangHotelChangeEvent$) {
      this.headerLangHotelChangeEvent$.unsubscribe();
    }
  }

  bookNow(id){
    const data = {checkIn: this.searchData.checkIn,
      checkOut: this.searchData.checkOut,
      guest: this.searchData.guest,
      RoomId: false
    }
    this.globalService.setRoomBookingData(data);
    this.router.navigate(['/rooms', id ]);
  }

  checkRoomLocation() {
    // API Requests For Different Locations
    if (
      this.roomLocationStatus == "home" ||
      this.roomLocationStatus == "homePageMenu"
    ) {
      this.globalService.showLoader = false;
      this.globalService.showLoader = false;
      this.showRoomLists({
        Data: "home",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    if (this.roomLocationStatus == "roomFrontView") {
      this.showRoomLists({
        Data: "roomFrontView",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    if (this.roomLocationStatus == "roomManagePage") {
      this.showRoomLists({
        Data: null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }
    if(this.roomLocationStatus == "roomSearch"){   // list for the guest room search
      this.showRoomLists({
        Data: null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }
  }

  showRoomLists(data) {
    if(this.searchData && this.searchData.hasOwnProperty("location")){
      this.masterService
        .showRoomLists(this.searchData)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              this.globalService.showLoader = false;
              this.globalService.emitPermissions(response.AdminPermissions);
              this.roomResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                RoomResponseArrayModel
              );
              this.roomLocationStatus="roomFrontView";
              if (this.roomResponseArrayModel.CountLists) {
                this.length = this.roomResponseArrayModel.CountLists;
              }
              this.checkRoomCount.emit(
                this.roomResponseArrayModel.RoomLists.length
              );
            } else {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            if(error.error.Message=='Result Not Found'){
              this.roomLocationStatus = 'noResultFound';
            }
            this.globalService.handleApiError(error);
          }
        );
        return;
    }    
    if (
      this.roomLocationStatus == "roomFrontView" ||
      this.roomLocationStatus == "home" ||
      this.roomLocationStatus == "homePageMenu"
    ) {
      this.masterService
        .showRoomLists(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              this.globalService.showLoader = false;
              this.globalService.emitPermissions(response.AdminPermissions);
              this.roomResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                RoomResponseArrayModel
              );
                                        if (this.roomResponseArrayModel.CountLists) {
                this.length = this.roomResponseArrayModel.CountLists;
              }
              this.checkRoomCount.emit(
                this.roomResponseArrayModel.RoomLists.length
              );
            } else {
              this.globalService.showLoader = false;
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(error);
          }
        );
    } else {
      this.masterService
        .showRoomListsAdmin(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              this.globalService.emitPermissions(response.AdminPermissions);
              this.roomResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                RoomResponseArrayModel
              );

                                        if (this.roomResponseArrayModel.CountLists) {
                this.length = this.roomResponseArrayModel.CountLists;
              }
              
              this.checkRoomCount.emit(
                this.roomResponseArrayModel.RoomLists.length
              );
            } else {
              this.globalService.showLoader = false;
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(error);
          }
        );
    }
  }

  detailRoomView(data) {
    this.detailRoomViewEmit.emit(data);
  }
  editRoomView(data) {
    this.editRoomViewEmit.emit(data);
  }

  deactivateFromHome(id) {
    let lists = this.roomResponseArrayModel.RoomLists;

    let findIndex = lists.findIndex(list => list._id === id);

    this.globalService.showLoader = true;
    this.masterService
      .updateRoomHomeStatus({
        Lang: this.masterService.getLangSaveProperty(),
        Id: id
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.roomResponseArrayModel.RoomLists.splice(findIndex, 1);
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  getPaginatorData(event) {
       if (this.roomLocationStatus == "roomFrontView") {
      this.showRoomLists({
        Data: "roomFrontView",
        pageIndex: event.pageSize * event.pageIndex,
        pageSize: event.pageSize
      });
    } else {
      this.showRoomLists({
        Data: null,
        pageIndex: event.pageSize * event.pageIndex,
        pageSize: event.pageSize
      });
    }
  }
}
