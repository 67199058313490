import { Directive, ElementRef, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { JwtService } from "../services/jwt.service";

@Directive({
  selector: "[appRouteRefresh]"
})
export class RouteRefreshDirective {
  constructor(private el: ElementRef, private router: Router, private jwtService:JwtService) {
    // el.nativeElement.style.backgroundColor = "yellow";
  }

  // @HostListener("mouseenter") onMouseEnter() {
  //   this.highlight("yellow");
  // }

  // private highlight(color: string) {
  //   this.el.nativeElement.style.backgroundColor = color;
  // }

  @HostListener("click", ["$event.target"])
  onClick(btn) {
    // this.router
    //   .navigateByUrl("/RefrshComponent", { skipLocationChange: true })
    //   .then(() =>
    //     this.router.navigate(["hotel/admin/hotel-configuration/homepage-menu"])
    //   );
    this.router.navigate([`/portal/${this.jwtService.getUserRole()}/hotel-configuration/homepage-menu`]);
  }
}
