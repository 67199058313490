import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { MasterService } from "../../services/master.service";
import { SharedService } from "src/app/shared.service";
import { GlobalService } from "../../services/global.service";
import { takeUntil } from "rxjs/operators";
import { ResturantResponseArrayModel } from "../../models/response/resturant-response";
import {  PageEvent } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { I } from "@angular/cdk/keycodes";
@Component({
  selector: "app-hotel-restaurants",
  templateUrl: "./hotel-restaurants.component.html",
  styleUrls: ["./hotel-restaurants.component.scss"]
})
export class HotelRestaurantsComponent implements OnInit {
  @Input() resturantLocationStatus: string;
  headerLangHotelChangeEvent$;

  public resturantResponseArrayModel: ResturantResponseArrayModel = new ResturantResponseArrayModel();

  @Output() detailResturantViewEmit = new EventEmitter();
  @Output() editResturantViewEmit = new EventEmitter();

  @Output() checkResturantCount = new EventEmitter();

  innerBannerShow: boolean = false;
  viewAllShow: boolean = true;
  headdingTitle = this.translate.instant("HotelRestuarants");

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  restaurantWithThreshold;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  isCheckedInUser
  // MatPaginator Output
  pageEvent: PageEvent;

  //Loader
  title = "materialApp";
  color = "primary";
  mode = "indeterminate";
  showLoader: boolean = true;
  constructor(
    private router: Router,
    private masterService: MasterService,
    private sharedService: SharedService,
    public globalService: GlobalService,
    public translate: TranslateService
  ) {}

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(",").map(str => +str);
  }

  ngOnInit() {
    // API Requests For Different Locations
    if(this.globalService.hasCheckInAuthToken()){
      this.isCheckedInUser=true
    }
    this.globalService.showLoader=true
    this.checkLocation();
    // if (
    //   this.resturantLocationStatus == "home" ||
    //   this.resturantLocationStatus == "homePageMenu"
    // ) {
    //   this.showResturantLists({
    //     Data: "home",
    //     pageIndex: this.pageIndex,
    //     pageSize: this.pageSize
    //   });
    // }

    // if (this.resturantLocationStatus == "resturantFrontView") {
    //   this.showResturantLists({
    //     Data: "resturantFrontView",
    //     pageIndex: this.pageIndex,
    //     pageSize: this.pageSize
    //   });
    // }

    // if (this.resturantLocationStatus == "resturantManagePage") {
    //   this.showResturantLists({
    //     Data: null,
    //     pageIndex: this.pageIndex,
    //     pageSize: this.pageSize
    //   });
    // }

    this.headerLangHotelChangeEvent$ = this.sharedService.headerLangChangeEvent.subscribe(
      (data: string) => {
        this.checkLocation();
      }
    );
  }

  ngOnDestroy() {
    if (this.headerLangHotelChangeEvent$) {
      this.headerLangHotelChangeEvent$.unsubscribe();
    }
  }

  checkLocation() {
    // API Requests For Different Locations
    if (
      this.resturantLocationStatus == "home" ||
      this.resturantLocationStatus == "homePageMenu"
    ) {
      this.globalService.showLoader = false;
      this.showResturantLists({
        Data: "home",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }

    if (this.resturantLocationStatus === "resturantFrontView") {
      this.showResturantLists({
        Data: "resturantFrontView",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        isCheckedInUser:this.isCheckedInUser?true:false

      });
    }

    if (this.resturantLocationStatus == "resturantManagePage") {
      this.showResturantLists({
        Data: null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
    }
  }

  showResturantLists(data) {
    this.globalService.showLoader = true;
    if (
      this.resturantLocationStatus == "resturantFrontView" ||
      this.resturantLocationStatus == "home" ||
      this.resturantLocationStatus == "homePageMenu"
    ) {
      this.globalService.showLoader = true;
      this.masterService
        .showResturantLists(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.resturantResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                ResturantResponseArrayModel
              );
              this.globalService.showLoader = false;
              let resturantResponseArray = this.resturantResponseArrayModel;


              if (this.resturantResponseArrayModel.CountLists) {
                this.length = this.resturantResponseArrayModel.CountLists;
              }

              if (
                this.resturantResponseArrayModel.ResturantLists &&
                this.resturantResponseArrayModel.ResturantLists.length
              ) {
                this.checkResturantCount.emit(
                  this.resturantResponseArrayModel.ResturantLists.length
                );
              } else {
                this.checkResturantCount.emit(0);
              }
            } else {
              this.globalService.showLoader = false;
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(error);
          }
        );
    } else {
      this.masterService
        .showResturantListsAdmin(data)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              this.restaurantWithThreshold = response.Data.restaurantWithThreshold;
              this.resturantResponseArrayModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                ResturantResponseArrayModel
              );

              let resturantResponseArray = this.resturantResponseArrayModel;

                           if (this.resturantResponseArrayModel.CountLists) {
                this.length = this.resturantResponseArrayModel.CountLists;
              }

              if (
                this.resturantResponseArrayModel.ResturantLists &&
                this.resturantResponseArrayModel.ResturantLists.length
              ) {
                this.checkResturantCount.emit(
                  this.resturantResponseArrayModel.ResturantLists.length
                );
              } else {
                this.checkResturantCount.emit(0);
              }
            } else {
              this.globalService.showLoader = false;
              // this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(error);
          }
        );
    }
  }

  detailResturantView(data) {
    this.detailResturantViewEmit.emit(data);
  }
  editResturantView(data) {
    this.editResturantViewEmit.emit(data);
  }

  deactivateFromHome(id) {
    let lists = this.resturantResponseArrayModel.ResturantLists;

    let findIndex = lists.findIndex(list => list._id === id);

    this.globalService.showLoader = true;
    this.masterService
      .updateResturantHomeStatus({
        Lang: this.masterService.getLangSaveProperty(),
        Id: id
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            this.resturantResponseArrayModel.ResturantLists.splice(
              findIndex,
              1
            );
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  thresholdCheck(id){
    if(this.restaurantWithThreshold.length > 0){
      for(let i=0; this.restaurantWithThreshold.length > i; i++){        
        if(this.restaurantWithThreshold[i].restaurantId == id){
          return true;
        }  
      }
      return false;
    }else{
      return false;      
    }
  }

  getPaginatorData(event) {
    // API Requests For Different Locations
    if (this.resturantLocationStatus == "resturantFrontView") {
      this.showResturantLists({
        Data: "resturantFrontView",
        pageIndex: event.pageSize * event.pageIndex,
        pageSize: event.pageSize
      });
    } else {
      this.showResturantLists({
        Data: null,
        pageIndex: event.pageSize * event.pageIndex,
        pageSize: event.pageSize
      });
    }
  }
}
