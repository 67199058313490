import { Component, OnInit, EventEmitter, Output, Inject } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { GlobalService } from "../../services/global.service";
import {MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Globals } from "../../globals";
import { MasterService } from "../../services/master.service";
import { patternValidator } from "src/app/pattern-validator/pattern-validator";

import "@ckeditor/ckeditor5-build-classic/build/translations/de";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  HotelGuideRequest,
  HotelGuideRequestLang
} from "../../models/common-request-response";
import { takeUntil } from "rxjs/operators";
import { AmazonWebService } from "../../services/amazon-web.service";
import { SharedService } from "src/app/shared.service";

@Component({
  selector: "app-add-new-hotel-guide",
  templateUrl: "./add-new-hotel-guide.component.html",
  styleUrls: ["./add-new-hotel-guide.component.scss"]
})
export class AddNewHotelGuideComponent implements OnInit {
  uploadSubscribe$;
  addNewHotelGuide: UntypedFormGroup;
  deletePdfSubscribe$;
  @Output() onAdd = new EventEmitter<any>(true);
  @Output() onUpdate = new EventEmitter<any>(true);
  public setPopupStatus = true;
  hotelGuideRequest: HotelGuideRequest = new HotelGuideRequest();
  updateStatus: boolean = false;
  insertStatus: boolean = false;
  public lang = this.globals.langSaveProperty;
  //File Upload Status-----------------------------
  public imgUrl = "";
  currentFileUpload: File;
  currentFileName: string;
  public uploadStatus: boolean = false;
  showLoader: boolean = false;
  public Editor = ClassicEditor;
  public config = {
    language: "'" + this.globalService.gethotelLangTranslate() + "'",
    toolbar: [
      "bold",
      "link",
      "italic",
      "bulletedList",
      "numberedList",
      "blockQuote"
    ]
  };
  updateId: any = null;
  constructor(
    private fb: UntypedFormBuilder,
    public globalService: GlobalService,
    private dialogRef: MatDialogRef<AddNewHotelGuideComponent>,
    public globals: Globals,
    private masterService: MasterService,
    private amazonWebService: AmazonWebService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.data.editStatus) {
      this.updateId = this.data.guildeLists._id;
      // 
      this.updateStatus = true;
      this.editForm(this.data.guildeLists);
      // 

      // let singleGuide = this.data.guildeLists;
    } else {
      this.insertStatus = true;
    }

    //Delete Pdf Subscribe---------------------------------
    this.deletePdfSubscribe$ = this.sharedService.deletePdfEvent.subscribe(
      data => {
        this.hotelGuideRequest.Pdf = "delete";
        this.hotelGuideRequest.FileName = "";
        this.addHotelGuide(this.hotelGuideRequest);
        this.imgUrl = "";
      }
    );
  }

  initForm() {
    this.addNewHotelGuide = this.fb.group({
      TitleGuide: ["", [Validators.required, patternValidator(/^[^<>%$]*$/)]],
      // HotelGuideDescription: [
      //   "",
      //   [Validators.required, patternValidator(/^[^<>%$]*$/)]
      // ]
      HotelGuideDescription: ""
    });
  }

  editForm(data) {
    var titleGuide = "";
    var hotelGuideDescription = "";
    if (data.TitleGuide[0] && data.TitleGuide) {
      titleGuide = data.TitleGuide[0].Value;
    }
    if (data.HotelGuideDescription[0] && data.HotelGuideDescription) {
      hotelGuideDescription = data.HotelGuideDescription[0].Value;
    }

    this.addNewHotelGuide.patchValue({
      TitleGuide: titleGuide,
      HotelGuideDescription: hotelGuideDescription
    });

    if (data.Pdf) {
      this.imgUrl = data.Pdf;
    }
    if (data.FileName) {
      this.currentFileName = data.FileName;
    }
  }

  switchLanguage(lang) {
    this.lang = lang;

    
    if (this.updateId) {
      this.masterService
        .showSingleHotelGuide({
          LangSave: lang,
          HotelGuideId: this.updateId
        })
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              // 
              if (response.Data) {
                let hotelGuideRequestLang = this.globalService.jsonConvert.deserialize(
                  response.Data,
                  HotelGuideRequestLang
                );
                this.editForm(hotelGuideRequestLang);
              }
            } else {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }

  upload(file) {
    this.uploadStatus = true;
    this.currentFileUpload = file.item(0);
    this.currentFileName = file[0].name;
  }

  addHotelGuide(hotelGuideRequest) {
    if (this.insertStatus) {
      if (this.data.count) {
        hotelGuideRequest.GuideOrder = this.data.count;
      } else {
        hotelGuideRequest.GuideOrder = this.data.count;
      }

      this.masterService
        .addHotelGuide({
          LangSave: this.lang,
          Data: hotelGuideRequest
        })
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              // 
              if (response.Data) {
                this.insertStatus = false;
                
                let fetchUpdateId = this.globalService.jsonConvert.deserializeObject(
                  response.Data,
                  HotelGuideRequestLang
                );

                this.updateId = fetchUpdateId._id;

                this.updateStatus = true;
                this.onAdd.emit({
                  id: response.Data,
                  value: hotelGuideRequest
                });
              } else {
                this.onAdd.emit(false); //If Already Exists
              }

              // this.dialogRef.close();
            } else {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }

    if (this.updateStatus) {
      this.masterService
        .updateHotelGuide({
          LangSave: this.lang,
          HotelGuideId: this.updateId,
          Data: hotelGuideRequest
        })
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;
              // 
              if (response.Data) {
                this.onUpdate.emit({
                  id: response.Data,
                  value: hotelGuideRequest
                });
              } else {
                this.onUpdate.emit(false); //If Already Exists
              }
              this.globalService.handleSuccessMessage(response.Message);
              //Donot Close the popup---------------------
              // this.dialogRef.close();
            } else {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }

  add(form) {
    // if (!form.invalid) {
    this.globalService.showLoader = true;
    this.hotelGuideRequest = form.value;

    if (this.uploadStatus) {
      this.globalService.showLoader = true;
      this.amazonWebService.uploadObject(
        this.currentFileName,
        this.currentFileUpload,
        "private",
        "pdf"
      );
      this.uploadSubscribe$ = this.amazonWebService.processCompleted.subscribe(
        response => {
          if (response.data.Location) {
            this.globalService.showLoader = false;
            // 
            //   this.globals.S3Url +
            //     this.globalService.getHostname() +
            //     "/pdf/" +
            //     response.data.Location
            // );
            this.hotelGuideRequest.Pdf =
              this.globals.S3Url +
              this.globalService.getHostname() +
              "/pdf/" +
              response.data.Location;

            this.hotelGuideRequest.FileName = this.currentFileName;
            // this.imgUrl =
            //   this.globals.S3Url +
            //   this.globalService.getHostname() +
            //   "/pdf/" +
            //   response.data.Location;
            this.uploadStatus = false;
            this.addHotelGuide(this.hotelGuideRequest);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
          return false;
        }
      );
    } else {
      this.addHotelGuide(this.hotelGuideRequest);
    }
    // } else {
    //   
    //   this.globalService.validateFormFields(form.form);
    // }
  }

  ngOnDestroy() {
    if (this.deletePdfSubscribe$) {
      this.deletePdfSubscribe$.unsubscribe();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
